<template lang="pug">
BaseCard
  BaseCardTitle 
    .flex.items-center
      .text-2xl Mis pagos

  .list-payments-wrapper
    paymentRowComponent(
      v-for="payment in listPayments",
      :key="payment._id",
      :payment="payment"
    )

  template(#card-bottom v-if="isSubscriptor")
    .flex.justify-between.items-center
      .message.access(v-if="isCanceledAccount") Acceso ilimitado hasta {{ dateFinalSubscription }}
      .button.ml-auto(
        v-if="!isCanceledAccount",
        @click="showModalCancel = true"
      ) Cancelar cuenta
BaseModal(:showModal="showModalCancel")
  .max-w-lg.w-full
    BaseCard
      BaseCardTitle 
        .flex.items-center
          .text-2xl Cancelar suscripción
      .content
        p.message Estas seguro de querer cancelar la suscripción?
        p.message Tras finalizar el periodo actual no podrás descargar tus CV's
      template(#card-bottom)
        .flex.items-center.justify-end.gap-4
          .button(@click="unsubscribe") Cancelar cuenta
          .button.close(@click="showModalCancel = false") Cerrar
</template>

<script>
import { UsersRepository } from '@/repository/users.repository';
import paymentRowComponent from './payment-row.component.vue';
import { mapActions, mapGetters } from 'vuex';
import parseDate from '@/composables/parse-date';

export default {
  name: 'payments-component',
  components: {
    paymentRowComponent
  },
  data: () => ({
    listPayments: [],
    showModalCancel: false
  }),
  created() {
    UsersRepository.listMePayments().then(
      listPaymentsData => (this.listPayments = listPaymentsData)
    );
  },
  methods: {
    ...mapActions({
      loadMeData: 'UserStore/loadMeData'
    }),
    async unsubscribe() {
      await UsersRepository.unSubscribMe()
        .then(() => this.loadMeData())
        .catch(({ response }) => {
          console.log(response);
        });
      this.showModalCancel = false;
    }
  },
  computed: {
    ...mapGetters({
      isCanceledAccount: 'UserAccountDataStore/isCanceledAccount',
      premiumEnds: 'UserAccountDataStore/premiumEnds',
      isSubscriptor: 'UserAccountDataStore/isSubscriptor'
    }),
    dateFinalSubscription() {
      return `${parseDate().parseDate(
        this.premiumEnds
      )} ${parseDate().parseHour(this.premiumEnds)}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-payments-wrapper {
  @apply flex flex-col mt-6;
}
.button {
  @apply text-gray-500 px-4 py-2 rounded-md cursor-pointer transition-colors;

  &:hover {
    @apply bg-gray-200;
  }

  &.cancel {
    @apply bg-red-400 text-white;
    &:hover {
      @apply bg-red-500;
    }
  }
  &.close {
    @apply bg-blue-400 text-white;
    &:hover {
      @apply bg-blue-500;
    }
  }
}

.content {
  @apply pt-6;
}
.message {
  @apply text-sm pt-2;

  &.access {
    @apply pt-0 text-gray-400 italic;
  }
}
</style>
