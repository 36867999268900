<template lang="pug">
.container.mt-12.mb-24
  baseH1Pages Mi Cuenta
  MenuPagesComponent.my-12(
    v-on:setComponent="(e) => (componentSelected = e)",
    :tabSelected="componentSelected"
  )
  component(:is="componentSelected")
</template>

<script>
import MenuPagesComponent from "@/components/www/dashboard/my-account/menu-pages.vue";
import AccessComponent from "@/components/www/dashboard/my-account/access-component.vue";
import PaymentsComponent from "@/components/www/dashboard/my-account/payments-component.vue";
export default {
  name: "my-account",
  components: {
    MenuPagesComponent,
    AccessComponent,
    PaymentsComponent,
  },
  data: () => ({
    componentSelected: "AccessComponent",
  }),
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 800px !important;
}
</style>
