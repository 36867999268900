<template lang="pug">
.box-payment
  .group-date 
    .date {{ paymentDate }}
    .hour {{ paymentHour }}
  .total {{ paymentAmount }}
  .description {{ paymentDescription }}
  .state(:class="stateClass") {{ paymentStateStatus }}
</template>

<script>
import parserDate from "@/composables/parse-date";

export default {
  name: "payment-row.component",
  props: {
    payment: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    stateClasses: {
      error: "error",
      succeeded: "success",
      requires_action: "warning",
    },
    currencies: {
      EUR: "€",
    },
    translateStates: {
      error: "Error",
      succeeded: "Correcto",
      requires_action: "Sín finalizar",
    },
  }),
  created() {
    console.log(this.payment);
  },
  computed: {
    paymentDate() {
      return parserDate().parseDate(this.payment.created_at);
    },
    paymentHour() {
      return parserDate().parseHour(this.payment.created_at);
    },
    paymentAmount() {
      const amount = this.payment.payment_amount / 100;
      return amount.toFixed(2) + this.currencies[this.payment.payment_currency];
    },
    paymentDescription() {
      return this.payment.payment_description;
    },
    stateClass() {
      const state = this.payment.payment_status;
      return this.stateClasses[state];
    },
    paymentStateStatus() {
      return this.translateStates[this.payment.payment_status];
    },
  },
};
</script>

<style lang="scss" scoped>
.box-payment {
  @apply flex items-center py-2 text-sm font-medium;

  & + .box-payment {
    @apply border-t;
  }

  .total {
    @apply ml-0;
  }

  .group-date {
    @apply text-gray-400 flex items-center;
    min-width: 120px;

    .date {
    }
    .hour {
      @apply text-xs text-gray-500 ml-1 font-light;
    }
  }

  .description {
    @apply pl-4 text-xs  font-normal text-gray-400;
  }

  .state {
    @apply px-4 py-1 rounded-full bg-gray-100 text-sm  text-center ml-auto;

    &.success {
      @apply bg-green-500 text-white;
    }

    &.error {
      @apply bg-red-500 text-white;
    }

    &.warning {
      @apply bg-yellow-500 text-white;
    }
  }
}
</style>
