<template lang="pug">
BaseCard
  BaseCardTitle
    .flex.items-center
      BaseCustomIcon.icon(nameIcon="mdiLockOutline")
      .text Cambiar contraseña
  .form.pt-4
    BaseInputText(label="Contraseña actual", v-model="user_password")
    BaseInputText(label="Nueva contraseña", v-model="user_password")
    BaseInputText(label="Repite la nueva contraseña", v-model="user_password")
  template(#card-bottom) 
    .flex.justify-end
      .button Guardar
</template>

<script>
export default {
  name: "form-password",
  data: () => ({
    user_password: "",
  }),
};
</script>

<style lang="scss" scoped>
.form {
  @apply flex flex-col gap-4;
}

.button {
  @apply bg-blue-600 text-white px-4 py-2 rounded-md;
}

.text {
  @apply text-base xs:text-xl;
}

.icon {
  @apply flex-shrink-0 mr-2;
}
</style>
